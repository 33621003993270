import React from 'react';
import './App.css';
import emotion from "./Icons/Small Icons/mood-24px-grey.svg";
import tactor from "./Icons/Small Icons/tactor-grey.svg";
import behavior from "./Icons/Small Icons/touch-grey.svg";
import neutral from "./Icons/Emotion/Emotion_Neutral.svg";
import happiness from "./Icons/Emotion/Emotion_Happiness .svg";
import fear from "./Icons/Emotion/Emotion_Fear.svg";
import sympathy from "./Icons/Emotion/Emotion_Sympathy.svg";
import sadness from "./Icons/Emotion/Emotion_Sadness.svg";
import anger from "./Icons/Emotion/Emotion_Anger.svg";
import disgust from "./Icons/Emotion/Emotion_Disgust.svg";
import love from "./Icons/Emotion/Emotion_Love.svg";
import gratitude from "./Icons/Emotion/Emotion_Gratitude.svg";
import emotionOther from "./Icons/Emotion/Emotion_Other.svg";
import shake from "./Icons/Tactor/Gesture icons_Shake.svg";
import squeeze from "./Icons/Tactor/Gesture icons_Squeeze-15.svg";
import hit from "./Icons/Tactor/Gesture icons_Hit.svg";
import pat from "./Icons/Tactor/Gesture icons_Pat-14.svg";
import push from "./Icons/Tactor/Gesture icons_Push-21.svg";
import rub from "./Icons/Tactor/Gesture icons_Rub-18.svg";
import stroke from "./Icons/Tactor/Gesture icons_Stroke.svg";
import tap from "./Icons/Tactor/Gesture icons_Tap-17.svg";
import trange from "./Icons/Small Icons/touch_range.svg";
import tspeed from "./Icons/Small Icons/speed-touch.svg";
import rspeed from "./Icons/Small Icons/speed_retreat.svg";
import randomness from "./Icons/Small Icons/Touch_randomness.svg";
import tint from "./Icons/Small Icons/touch_interval.svg";
import irand from "./Icons/Small Icons/interval_randomness.svg";
import play from "./Icons/play-filled.svg";
import pause from "./Icons/pause-filled.svg";
import del from "./Icons/delete.svg";
import edit from "./Icons/edit.svg";
import pauseOutline from "./Icons/pause-unfilled.svg";
import playOutline from "./Icons/play-unfilled.svg";
import download from "./Icons/download.svg";
import settings from "./Icons/settings.svg";
import arrow from "./Icons/Small Icons/arrow.svg";
import hitw from "./Icons/Tactor_white/Gesture_white_Hit.svg";
import patw from "./Icons/Tactor_white/Gesture_white_Pat-14.svg";
import pushw from "./Icons/Tactor_white/Gesture_white_Push-21.svg";
import rubw from "./Icons/Tactor_white/Gesture_white_Rub-18.svg";
import shakew from "./Icons/Tactor_white/Gesture_white_Shake.svg";
import squeezew from "./Icons/Tactor_white/Gesture_white_Squeeze-15.svg";
import strokew from "./Icons/Tactor_white/Gesture_white_Stroke.svg";
import tapw from "./Icons/Tactor_white/Gesture_white_Tap-17.svg";
import link from "./Icons/link.svg";
import patup from "./Icons/Tactor Image/Pat/yellow_pat_up.png";
import patcut from "./Icons/Tactor Image/Pat/yellow_pat_cut.png";
import pushup from "./Icons/Tactor Image/Push/yellow_push_up.png";
import pushcut from "./Icons/Tactor Image/Push/yellow_push_cut.png";
import rubup from "./Icons/Tactor Image/Rub/yellow_rub_up.png";
import rubcut from "./Icons/Tactor Image/Rub/yellow_rub_cut.png";
import shakeup from "./Icons/Tactor Image/Shake/yellow_shake_up.png";
import shakecut from "./Icons/Tactor Image/Shake/yellow_shake_cut.png";
import squeezeup from "./Icons/Tactor Image/Squeeze/yellow_squeeze_up.png";
import squeezecut from "./Icons/Tactor Image/Squeeze/yellow_squeeze_cut.png";
import strokeup from "./Icons/Tactor Image/Stroke/yellow_stroke_up.png";
import strokecut from "./Icons/Tactor Image/Stroke/yellow_stroke_cut.png";
import tapup from "./Icons/Tactor Image/Tap/yellow_tap_up.png";
import tapcut from "./Icons/Tactor Image/Tap/yellow_tap_cut.png";
import hitplace from "./Icons/Placement Image/Hit_placement-12.png";
import patplace from "./Icons/Placement Image/Pat_placement-13.png";
import pushplace from "./Icons/Placement Image/Push_placement-14.png";
import rubplace from "./Icons/Placement Image/Rub_placement-15.png";
import shakeplace from "./Icons/Placement Image/Shake_placement-16.png";
import squeezeplace from "./Icons/Placement Image/Squeeze_placement-17.png";
import strokeplace from "./Icons/Placement Image/Stroke_placement-18.png";
import tapplace from "./Icons/Placement Image/Tap_placement-19.png";
import homeconnect from "./Icons/Small Icons/home_connect-24px-grey.svg";
import serialconnect from "./Icons/Small Icons/serial_connect-24px-grey.svg";
import icon_tactorconnect from "./Icons/Small Icons/tactor_connect-24px-grey.svg";
import homeconnect_pc from "./Icons/Connection/home_connect_pc.svg";
import homeconnect_power from "./Icons/Connection/home_connect_power.svg";
import tactortip1 from "./Icons/Connection/tactor-fig-1.svg";
import tactorports from "./Icons/Connection/tactor-ports.svg";
import tcfig1 from "./Icons/Connection/tc-fig1.svg";
import tcfig2 from "./Icons/Connection/tc-fig2.svg";
import tcfig3 from "./Icons/Connection/tc-fig3.svg";

import Emotion from './emotion';
import Sketch from 'react-p5';

import { ToastContainer, toast, Slide } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import IonRangeSlider from 'react-ion-slider'

const backendURL = "https://tactorbots.com/backend/api.php"

class LeftNav extends React.Component{
angle = 0;
count = 0;
x =0;
timer;
from = false;
volhistory = [];

port = null;
writer = null;
reader = null;

serialmsg = [0, 0, 0, 0, 0, 0, 0, 0, 0];

constructor(props) {
  super(props);
  
  let foundUser = 'default';
  let loggedInUser = localStorage.getItem("username");
  if (loggedInUser) {
    foundUser = loggedInUser
    this.setState({username: foundUser});
  }

  this.state = {
    home: true,
    emotion: false,
    estate: null,
    tactor: false,
    tstate: null,
    behavior: false,
    bstate: null,
    eselected:false,
    eicon: null,
    ename: null,
    tselected:false,
    ticon: null,
    tname: null,
    trange:[45,90,0,180],
    tspeed:[180,0,360],
    rspeed:[180,0,360],
    randomness:[25,0,50],
    tint:[500,0,2000],
    irand:[150,0,2000],
    shake: null,
    squeeze: null,
    hit: null,
    pat: null,
    push: null,
    rub: null,
    stroke: null,
    tap: null,
    chome: true,
    homeconnect: true,
    hcstate: "active",
    serialconnect: false,
    scstate: null,
    tactorconnect: false,
    tcstate1:null, 
    tcstate2:null,
    port:null,
    generator:[true,"active"],
    tinfo:[false, null],
    connection: [false, null],
    archive:[false,null],
    shake1:[false,null,false,"Tactor 4", "Port 4"], //selected, "active", activated
    squeeze1:[false,null,false, "Tactor 5", "Port 5"],
    hit1:[true, "active",false, "Tactor 1", "Port 1"],
    pat1:[false,null,false, "Tactor 1", "Port 1"],
    push1:[false,null,false, "Tactor 2", "Port 2"],
    rub1: [false,null,false, "Tactor 3", "Port 3"],
    stroke1: [false,null,false, "Tactor 6", "Port 6"],
    tap1: [false,null,false, "Tactor 7", "Port 7"],
    place:hitplace,
    cut:patcut,
    up:patup,
    acttact:[hit, "Tactor 1", "Port 1", "Hit", false],
    happiness:null,
    fear:null,
    sympathy:null,
    sadness:null,
    anger: null,
    disgust: null,
    love: null,
    gratitude: null,
    otherEmotion: null,
    archiveData: [],
    username: foundUser,
    fileName: "Timeline Sample 1",
  };
  this.handleChange = this.handleChange.bind(this);
}

handleChange(event) {
  this.setState({fileName: event.target.value});
}

async write(data){
  if(this.state.port === true){

    var serialString = "<";

    for(var i=0;i<data.length;i++){
      serialString += parseInt(data[i]).toString();
      serialString += ",";
    }
    serialString = serialString.replace(/.$/,">");

    console.debug("Outgoing Serial: ", serialString);

    var encoder = new TextEncoder();
    await this.writer.write(encoder.encode(serialString));

    // try {
    //   const readerData = await this.reader.read();
    //   console.log(readerData);
    // } catch (err) {
    //   const errorMessage = `error reading data: ${err}`;
    //   console.error(errorMessage);
    //   return errorMessage;
    // }

  } else {
    toast("Please Connect to Serial Port")
    this.handleClick("serialConnect");
    this.handleClick("connection");
  }
};

async start() {

  if ('serial' in navigator) {
    if (!this.state.port) {
      try {
        this.port = await navigator.serial.requestPort();
        await this.port.open({ baudRate: 9600 });

        this.writer = this.port.writable.getWriter();
        this.reader = this.port.readable.getReader();
        
        this.setState({ port: true, });
        
        // console.debug(this.port);

        toast("Serial Port Connected");
      }
      catch (err) {
        toast("Error Connecting to Serial Port");
        this.setState({ port: false, });
        console.info('There was an error opening the serial port:', err);
      }
    }
  }

  else {
    toast("Web Serial API is not enabled. See console (F12) on how to enable it.")
    console.info('The Web serial API doesn\'t seem to be enabled in your browser.');
    console.info('chrome://flags/#enable-experimental-web-platform-features');
  }

};

handleClick(name) {
  var hitstate=this.state.hit1;
  var patstate=this.state.pat1;
  var pushstate=this.state.push1;
  var rubstate=this.state.rub1;
  var shakestate=this.state.shake1;
  var squeezestate=this.state.squeeze1;
  var strokestate=this.state.stroke1;
  var tapstate=this.state.tap1;
  switch (name) {
    case "emotion":
      this.setState({ home: false, emotion:true, estate:"active", tactor: false, tstate:null, behavior: false, bstate:null});
      //alert(localStorage.getItem('test'));
      break;
    case "tactor":
      this.setState({ home: false, emotion: false, estate:null, tactor: true, tstate:"active", behavior: false, bstate: null});
      break;
    case "emotionselect":
      if(this.state.tselected){
        this.setState({home: false, emotion: false, estate:null, tactor: false, tstate:null, behavior: true, bstate:"active"});
      }else{
        this.setState({home: false, emotion: false, estate:null, tactor: true, tstate:"active", behavior: false, bstate: null});
      }
      break;
    case "tactselect":
      if(this.state.eselected){
        this.setState({home: false, emotion: false, estate:null, tactor: false, tstate:null, behavior: true, bstate:"active"});
      }else{
        this.setState({ home: false, emotion:true, estate:"active", tactor: false, tstate:null, behavior: false, bstate:null});
      }
      break;
    case "behavior":
    this.setState({home: false, emotion: false, estate:null, tactor: false, tstate:null, behavior: true, bstate:"active"});
      break;
    case "tbots":
      window.location.reload(false);
    // this.setState({home: true, emotion:true, estate:null, tactor: false, tstate:null, behavior: false, bstate:null});
    break;
    // connection tab pages
    case "homeConnect": 
    this.setState({ homeconnect:true, hcstate:"active", serialconnect: false, scstate:null, tactorconnect: false, tcstate1:null, tcstate2:null});
    break;
    case "serialConnect": 
    this.setState({ homeconnect:false, hcstate:null, serialconnect: true, scstate:"active", tactorconnect: false, tcstate1:null, tcstate2:null});
    break;
    case "selectPort":
      this.start();
    break;
    case "tactorConnect": 
    this.setState({ homeconnect:false, hcstate:null, serialconnect: false, scstate:null, tactorconnect: true, tcstate1:"active", tcstate2: null});
    break;
    case "tactorConnect2": 
    this.setState({ homeconnect:false, hcstate:null, serialconnect: false, scstate:null, tactorconnect: true, tcstate1: null, tcstate2:"active"});
    break;
    // Topbar Page Tabs
    case "tgen":
    this.setState({generator:[true,"active"], tinfo:[false,null], connection:[false, null], archive:[false,null]});
    break;
    case "tinfo":
    this.setState({generator:[false,null], tinfo:[true,"active"], connection:[false, null], archive:[false,null]});
    break;
    case "connection":
    this.setState({generator:[false,null], tinfo:[false, null], connection:[true,"active"], archive:[false,null]});
    break;
    case "archive":
    this.setState({generator:[false,null], tinfo:[false,null], connection:[false, null], archive:[true,"active"]});
    // alert("Archive is not yet an active feature. Stay Tuned!");
    break;
    // Emotions
    case "hit":
    if(hitstate[2]){
      if(this.state.eselected){

        this.setState({home: false, emotion: false, estate:null, tactor: false, tstate:null, behavior: true, bstate:"active"});
      }else{

        this.setState({ home: false, emotion:true, estate:"active", tactor: false, tstate:null, behavior: false, bstate:null});
      }
      break;
    }else{
      toast("You need to activate this tactor before continuing.");

      this.setState({generator:[false,null], tinfo:[true,"active"], connection:[false, null], archive:[false,null]});
      this.setState({place:hitplace,cut:patcut,up:patup,acttact:[hit,hitstate[3],hitstate[4], "Hit"],hit1:[true,"active",hitstate[2],hitstate[3],hitstate[4]], pat1:[false,null,patstate[2],patstate[3],patstate[4]], push1:[false,null,pushstate[2],pushstate[3],pushstate[4]], rub1:[false,null,rubstate[2],rubstate[3],rubstate[4]],shake1:[false,null,shakestate[2],shakestate[3],shakestate[4]],squeeze1:[false,null,squeezestate[2],squeezestate[3],squeezestate[4]],stroke1:[false,null,strokestate[2],strokestate[3],strokestate[4]],tap1:[false,null,tapstate[2],tapstate[3],tapstate[4]],});

    }
    break;
    case "pat":
    if(patstate[2]){
      if(this.state.eselected){

        this.setState({home: false, emotion: false, estate:null, tactor: false, tstate:null, behavior: true, bstate:"active"});
      }else{

        this.setState({ home: false, emotion:true, estate:"active", tactor: false, tstate:null, behavior: false, bstate:null});
      }
      break;
    }else{
      toast("You need to activate this tactor before continuing.");

      this.setState({generator:[false,null], tinfo:[true,"active"], connection:[false, null], archive:[false,null]});
      this.setState({place:patplace,cut:patcut,up:patup,acttact:[pat,patstate[3],patstate[4], "Pat"],hit1:[false,null,hitstate[2],hitstate[3],hitstate[4]], pat1:[true,"active",patstate[2],patstate[3],patstate[4]], push1:[false,null,pushstate[2],pushstate[3], pushstate[4]], rub1:[false,null,rubstate[2],rubstate[3],rubstate[4]],shake1:[false,null,shakestate[2],shakestate[3],shakestate[4]],squeeze1:[false,null,squeezestate[2],squeezestate[3],squeezestate[4]],stroke1:[false,null,strokestate[2],strokestate[3],strokestate[4]],tap1:[false,null,tapstate[2],tapstate[3],tapstate[4]],});
    }
    break;
    case "push":
    if(pushstate[2]){
      if(this.state.eselected){

        this.setState({home: false, emotion: false, estate:null, tactor: false, tstate:null, behavior: true, bstate:"active"});
      }else{

        this.setState({ home: false, emotion:true, estate:"active", tactor: false, tstate:null, behavior: false, bstate:null});
      }
      break;
    }else{
      toast("You need to activate this tactor before continuing.");

      this.setState({generator:[false,null], tinfo:[true,"active"], connection:[false, null], archive:[false,null]});
      this.setState({place:pushplace,cut:pushcut,up:pushup,acttact:[push,pushstate[3],pushstate[4], "Push"],hit1:[false,null,hitstate[2],hitstate[3],hitstate[4]], pat1:[false,null,patstate[2],patstate[3],patstate[4]], push1:[true,"active",pushstate[2],pushstate[3],pushstate[4]], rub1:[false,null,rubstate[2],rubstate[3],rubstate[4]],shake1:[false,null,shakestate[2],shakestate[3],shakestate[4]],squeeze1:[false,null,squeezestate[2],squeezestate[3],squeezestate[4]],stroke1:[false,null,strokestate[2],strokestate[3],strokestate[4]],tap1:[false,null,tapstate[2],tapstate[3],tapstate[4]],});

    }
    break;
    case "rub":
    if(rubstate[2]){
      if(this.state.eselected){

        this.setState({home: false, emotion: false, estate:null, tactor: false, tstate:null, behavior: true, bstate:"active"});
      }else{

        this.setState({ home: false, emotion:true, estate:"active", tactor: false, tstate:null, behavior: false, bstate:null});
      }
      break;
    }else{
      toast("You need to activate this tactor before continuing.");

      this.setState({generator:[false,null], tinfo:[true,"active"], connection:[false, null], archive:[false,null]});
      this.setState({place:rubplace,cut:rubcut,up:rubup,acttact:[rub,rubstate[3],rubstate[4], "Rub"],hit1:[false,null,hitstate[2],hitstate[3],hitstate[4]], pat1:[false,null,patstate[2],patstate[3],patstate[4]], push1:[false,null,pushstate[2],pushstate[3],pushstate[4]], rub1:[true,"active",rubstate[2],rubstate[3],rubstate[4]],shake1:[false,null,shakestate[2],shakestate[3],shakestate[4]],squeeze1:[false,null,squeezestate[2],squeezestate[3],squeezestate[4]],stroke1:[false,null,strokestate[2],strokestate[3],strokestate[4]],tap1:[false,null,tapstate[2],tapstate[3],tapstate[4]],});

    }

    break;
    case "shake":
    if(shakestate[2]){
      if(this.state.eselected){

        this.setState({home: false, emotion: false, estate:null, tactor: false, tstate:null, behavior: true, bstate:"active"});
      }else{

        this.setState({ home: false, emotion:true, estate:"active", tactor: false, tstate:null, behavior: false, bstate:null});
      }
      break;
    }else{
      toast("You need to activate this tactor before continuing.");

      this.setState({generator:[false,null], tinfo:[true,"active"], connection:[false, null], archive:[false,null]});
      this.setState({place:shakeplace,cut:shakecut,up:shakeup,acttact:[shake,shakestate[3],shakestate[4], "Shake"],hit1:[false,null,hitstate[2],hitstate[3],hitstate[4]], pat1:[false,null,patstate[2],patstate[3],patstate[4]], push1:[false,null,shakestate[2],shakestate[3],shakestate[4]], rub1:[false,null,rubstate[2],rubstate[3],rubstate[4]],shake1:[true,"active",shakestate[2],shakestate[3],shakestate[4]],squeeze1:[false,null,squeezestate[2],squeezestate[3],squeezestate[4]],stroke1:[false,null,strokestate[2],strokestate[3],strokestate[4]],tap1:[false,null,tapstate[2],tapstate[3],tapstate[4]],});

    }

    break;
    case "squeeze":
    if(squeezestate[2]){
      if(this.state.eselected){

        this.setState({home: false, emotion: false, estate:null, tactor: false, tstate:null, behavior: true, bstate:"active"});
      }else{

        this.setState({ home: false, emotion:true, estate:"active", tactor: false, tstate:null, behavior: false, bstate:null});
      }
      break;
    }else{
      toast("You need to activate this tactor before continuing.");
      this.setState({generator:[false,null], tinfo:[true,"active"], connection:[false, null], archive:[false,null]});
      this.setState({place:squeezeplace,cut:squeezecut,up:squeezeup,acttact:[squeeze,squeezestate[3],squeezestate[4], "Squeeze"],hit1:[false,null,hitstate[2],hitstate[3],hitstate[4]], pat1:[false,null,patstate[2],patstate[3],patstate[4]], push1:[false,null,pushstate[2],pushstate[3],pushstate[4]], rub1:[false,null,rubstate[2],rubstate[3],rubstate[4]],shake1:[false,null,shakestate[2],shakestate[3],shakestate[4]],squeeze1:[true,"active",squeezestate[2],squeezestate[3],squeezestate[4]],stroke1:[false,null,strokestate[2],strokestate[3],strokestate[4]],tap1:[false,null,tapstate[2],tapstate[3],tapstate[4]],});

    }

    break;
    case "stroke":
    if(strokestate[2]){
      if(this.state.eselected){

        this.setState({home: false, emotion: false, estate:null, tactor: false, tstate:null, behavior: true, bstate:"active"});
      }else{

        this.setState({ home: false, emotion:true, estate:"active", tactor: false, tstate:null, behavior: false, bstate:null});
      }
      break;
    }else{
      toast("You need to activate this tactor before continuing.");
      this.setState({generator:[false,null], tinfo:[true,"active"], connection:[false, null], archive:[false,null]});
      this.setState({place:strokeplace,cut:strokecut,up:strokeup,acttact:[stroke,strokestate[3],strokestate[4], "Stroke"],hit1:[false,null,hitstate[2],hitstate[3],hitstate[4]], pat1:[false,null,patstate[2],patstate[3],patstate[4]], push1:[false,null,pushstate[2],pushstate[3],pushstate[4]], rub1:[false,null,rubstate[2],rubstate[3],rubstate[4]],shake1:[false,null,shakestate[2],shakestate[3],shakestate[4]],squeeze1:[false,null,squeezestate[2],squeezestate[3],squeezestate[4]],stroke1:[true,"active",strokestate[2],strokestate[3],strokestate[4]],tap1:[false,null,tapstate[2],tapstate[3],tapstate[4]],});

    }

    break;
    case "tap":
    if(tapstate[2]){
      if(this.state.eselected){

        this.setState({home: false, emotion: false, estate:null, tactor: false, tstate:null, behavior: true, bstate:"active"});
      }else{

        this.setState({ home: false, emotion:true, estate:"active", tactor: false, tstate:null, behavior: false, bstate:null});
      }
      break;
    }else{
      toast("You need to activate this tactor before continuing.");
      this.setState({generator:[false,null], tinfo:[true,"active"], connection:[false, null], archive:[false,null]});
      this.setState({place:tapplace,cut:tapcut,up:tapup,acttact:[tap,tapstate[3],tapstate[4],"Tap"],hit1:[false,null,hitstate[2],hitstate[3],hitstate[4]], pat1:[false,null,patstate[2],patstate[3],patstate[4]], push1:[false,null,pushstate[2],pushstate[3],pushstate[4]], rub1:[false,null,rubstate[2],rubstate[3],rubstate[4]],shake1:[false,null,shakestate[2],shakestate[3],shakestate[4]],squeeze1:[false,null,squeezestate[2],squeezestate[3],squeezestate[4]],stroke1:[false,null,strokestate[2],strokestate[3],strokestate[4]],tap1:[true,"active",tapstate[2],tapstate[3],tapstate[4]],});

    }

    break;
    // Emotions Storage
    case "hit1":
    this.setState({place:hitplace,cut:patcut,up:patup,acttact:[hit,hitstate[3],hitstate[4], "Hit"],hit1:[true,"active",hitstate[2],hitstate[3],hitstate[4]], pat1:[false,null,patstate[2],patstate[3],patstate[4]], push1:[false,null,pushstate[2],pushstate[3],pushstate[4]], rub1:[false,null,rubstate[2],rubstate[3],rubstate[4]],shake1:[false,null,shakestate[2],shakestate[3],shakestate[4]],squeeze1:[false,null,squeezestate[2],squeezestate[3],squeezestate[4]],stroke1:[false,null,strokestate[2],strokestate[3],strokestate[4]],tap1:[false,null,tapstate[2],tapstate[3],tapstate[4]],});
    break;
    case "pat1":
    this.setState({place:patplace,cut:patcut,up:patup,acttact:[pat,patstate[3],patstate[4], "Pat"],hit1:[false,null,hitstate[2],hitstate[3],hitstate[4]], pat1:[true,"active",patstate[2],patstate[3],patstate[4]], push1:[false,null,pushstate[2],pushstate[3], pushstate[4]], rub1:[false,null,rubstate[2],rubstate[3],rubstate[4]],shake1:[false,null,shakestate[2],shakestate[3],shakestate[4]],squeeze1:[false,null,squeezestate[2],squeezestate[3],squeezestate[4]],stroke1:[false,null,strokestate[2],strokestate[3],strokestate[4]],tap1:[false,null,tapstate[2],tapstate[3],tapstate[4]],});
    break;
    case "push1":
    this.setState({place:pushplace,cut:pushcut,up:pushup,acttact:[push,pushstate[3],pushstate[4], "Push"],hit1:[false,null,hitstate[2],hitstate[3],hitstate[4]], pat1:[false,null,patstate[2],patstate[3],patstate[4]], push1:[true,"active",pushstate[2],pushstate[3],pushstate[4]], rub1:[false,null,rubstate[2],rubstate[3],rubstate[4]],shake1:[false,null,shakestate[2],shakestate[3],shakestate[4]],squeeze1:[false,null,squeezestate[2],squeezestate[3],squeezestate[4]],stroke1:[false,null,strokestate[2],strokestate[3],strokestate[4]],tap1:[false,null,tapstate[2],tapstate[3],tapstate[4]],});
    break;
    case "rub1":
    this.setState({place:rubplace,cut:rubcut,up:rubup,acttact:[rub,rubstate[3],rubstate[4], "Rub"],hit1:[false,null,hitstate[2],hitstate[3],hitstate[4]], pat1:[false,null,patstate[2],patstate[3],patstate[4]], push1:[false,null,pushstate[2],pushstate[3],pushstate[4]], rub1:[true,"active",rubstate[2],rubstate[3],rubstate[4]],shake1:[false,null,shakestate[2],shakestate[3],shakestate[4]],squeeze1:[false,null,squeezestate[2],squeezestate[3],squeezestate[4]],stroke1:[false,null,strokestate[2],strokestate[3],strokestate[4]],tap1:[false,null,tapstate[2],tapstate[3],tapstate[4]],});
    break;
    case "shake1":
    this.setState({
      place:shakeplace,
      cut:shakecut,
      up:shakeup,
      acttact:[shake,shakestate[3],shakestate[4], "Shake"],
      hit1:[false,null,hitstate[2],hitstate[3],hitstate[4]], 
      pat1:[false,null,patstate[2],patstate[3],patstate[4]], 
      push1:[false,null,pushstate[2],pushstate[3],pushstate[4]], 
      rub1:[false,null,rubstate[2],rubstate[3],rubstate[4]],
      shake1:[true,"active",shakestate[2],shakestate[3],shakestate[4]],squeeze1:[false,null,squeezestate[2],squeezestate[3],squeezestate[4]],stroke1:[false,null,strokestate[2],strokestate[3],strokestate[4]],tap1:[false,null,tapstate[2],tapstate[3],tapstate[4]],});
    break;
    case "squeeze1":
    this.setState({place:squeezeplace,cut:squeezecut,up:squeezeup,acttact:[squeeze,squeezestate[3],squeezestate[4], "Squeeze"],hit1:[false,null,hitstate[2],hitstate[3],hitstate[4]], pat1:[false,null,patstate[2],patstate[3],patstate[4]], push1:[false,null,pushstate[2],pushstate[3],pushstate[4]], rub1:[false,null,rubstate[2],rubstate[3],rubstate[4]],shake1:[false,null,shakestate[2],shakestate[3],shakestate[4]],squeeze1:[true,"active",squeezestate[2],squeezestate[3],squeezestate[4]],stroke1:[false,null,strokestate[2],strokestate[3],strokestate[4]],tap1:[false,null,tapstate[2],tapstate[3],tapstate[4]],});
    break;
    case "stroke1":
    this.setState({
      place:strokeplace,
      cut:strokecut,
      up:strokeup,
      acttact:[stroke,strokestate[3],strokestate[4], "Stroke"],
      hit1:[false,null,hitstate[2],hitstate[3],hitstate[4]], 
      pat1:[false,null,patstate[2],patstate[3],patstate[4]], 
      push1:[false,null,pushstate[2],pushstate[3],pushstate[4]], 
      rub1:[false,null,rubstate[2],rubstate[3],rubstate[4]],
      shake1:[false,null,shakestate[2],shakestate[3],shakestate[4]],
      squeeze1:[false,null,squeezestate[2],squeezestate[3],squeezestate[4]],
      stroke1:[true,"active",strokestate[2],strokestate[3],strokestate[4]],
      tap1:[false,null,tapstate[2],tapstate[3],tapstate[4]],});
    break;
    case "tap1":
    this.setState({place:tapplace,cut:tapcut,up:tapup,acttact:[tap,tapstate[3],tapstate[4],"Tap"],hit1:[false,null,hitstate[2],hitstate[3],hitstate[4]], pat1:[false,null,patstate[2],patstate[3],patstate[4]], push1:[false,null,pushstate[2],pushstate[3],pushstate[4]], rub1:[false,null,rubstate[2],rubstate[3],rubstate[4]],shake1:[false,null,shakestate[2],shakestate[3],shakestate[4]],squeeze1:[false,null,squeezestate[2],squeezestate[3],squeezestate[4]],stroke1:[false,null,strokestate[2],strokestate[3],strokestate[4]],tap1:[true,"active",tapstate[2],tapstate[3],tapstate[4]],});
    break;
    // Archive Function
    case "save2archive":
    
    if(this.state.username !== 'default'){
      var formdata = new FormData();

      formdata.append("name", this.state.fileName);
      formdata.append("emotion", this.state.ename);
      formdata.append("tactor", this.state.tname);
      formdata.append("touch_range_min", this.state.trange[0]);
      formdata.append("touch_range_max", this.state.trange[1]);
      formdata.append("touch_speed", this.state.tspeed[0]);
      formdata.append("retreat_speed", this.state.rspeed[0]);
      formdata.append("touch_random", this.state.randomness[0]);
      formdata.append("touch_interval", this.state.tint[0]);
      formdata.append("interval_random", this.state.irand[0]);

      var requestOptions = {
        method: 'POST',
        body: formdata,
        redirect: 'follow'
      };

      fetch(`${backendURL}/archive/${this.state.username}`, requestOptions)
        .then(response => response.json())
        .then(data => data.response==="success"?toast("Saved Successfully ✅"):toast("❌ Error while saving"))
        .then(() => this.getArchiveData());
    } else {
      toast("Please login before saving your emotion. Cannot save to username: 'default'");
    }
    break;
    // Login Function
    case "login": 
    let name = window.prompt("Enter your username", "default");
    if(name !== null) {
      console.log(name);
      fetch(`${backendURL}/user/${name}`, requestOptions)
        .then(response => response.json())
        .then(data => {
          if (data.response !== "success") {
            name = 'default';
            toast("Inavlid Username");
          }

          this.setState({
            username: name
          }, () => {
            toast(({ closeToast }) => <div>Username set to [<b>{this.state.username}</b>]</div>);
            localStorage.setItem("username", this.state.username);
            this.getArchiveData();
          });
        });
    }
    break;
    default: console.log("handleClick: defaultState");
  }
}

isActive(name) {
  let status = false;
  switch (name.toLowerCase()) {
    case "hit":
      status = this.state.hit1[2];
      break;
    case "pat":
      status = this.state.pat1[2];
      break;
    case "push":
      status = this.state.push1[2];
      break;
    case "rub":
      status =this.state.rub1[2];
      break;
    case "shake":
      status = this.state.shake1[2];
      break;
    case "squeeze":
      status = this.state.squeeze1[2];
      break;
    case "stroke":
      status = this.state.stroke1[2];
      break;
    case "tap":
      status = this.state.tap1[2];
      break;
    default:
      status = false;
      console.log(">isActive: default", name);
  }
  return status;
}

sendTactorStatus(){
  var data = [99, 
    Number(this.state.hit1[2]) | Number(this.state.pat1[2]), 
    Number(this.state.push1[2]),
    Number(this.state.rub1[2]),
    Number(this.state.shake1[2]),
    Number(this.state.squeeze1[2]),
    Number(this.state.stroke1[2]),
    Number(this.state.tap1[2])
  ];
  this.write(data);
}

activate(name, callback = () => {}) {
  let modedCallback = () => {
    this.sendTactorStatus();
    callback();
  }

  if (!this.isActive(name)) {
    var hitstate = this.state.hit1;
    var patstate = this.state.pat1;
    var pushstate = this.state.push1;
    var rubstate = this.state.rub1;
    var shakestate = this.state.shake1;
    var squeezestate = this.state.squeeze1;
    var strokestate = this.state.stroke1;
    var tapstate = this.state.tap1;

    switch (name) {
      case "Hit":
        this.setState({
          hit1: [hitstate[0], hitstate[1], true, hitstate[3], hitstate[4]]
        }, modedCallback);
        break;
      case "Pat":
        this.setState({
          pat1: [patstate[0], patstate[1], true, patstate[3], patstate[4]]
        }, modedCallback);
        break;
      case "Push":
        this.setState({
          push1: [pushstate[0], pushstate[1], true, pushstate[3], pushstate[4]]
        }, modedCallback);
        break;
      case "Rub":
        this.setState({
          rub1: [rubstate[0], rubstate[1], true, rubstate[3], rubstate[4]]
        }, modedCallback);
        break;
      case "Shake":
        this.setState({
          shake1: [shakestate[0], shakestate[1], true, shakestate[3], shakestate[4]]
        }, modedCallback);
        break;
      case "Squeeze":
        this.setState({
          squeeze1: [squeezestate[0], squeezestate[1], true, squeezestate[3], squeezestate[4]]
        }, modedCallback);
        break;
      case "Stroke":
        this.setState({
          stroke1: [strokestate[0], strokestate[1], true, strokestate[3], strokestate[4]]
        }, modedCallback);
        break;
      case "Tap":
        this.setState({
          tap1: [tapstate[0], tapstate[1], true, tapstate[3], tapstate[4]]
        }, modedCallback);
        break;
      default:
        console.log("activate: defaultState", name);
    }
    toast(name + " was activated!");
  } else {
    modedCallback();
  }
}

deactivate(name, callback = () => {}) {
  
  let modedCallback = () => {
    this.sendTactorStatus();
    callback();
  }

    if (this.isActive(name)) {
      var hitstate = this.state.hit1;
      var patstate = this.state.pat1;
      var pushstate = this.state.push1;
      var rubstate = this.state.rub1;
      var shakestate = this.state.shake1;
      var squeezestate = this.state.squeeze1;
      var strokestate = this.state.stroke1;
      var tapstate = this.state.tap1;
  
      switch (name) {
        case "Hit":
          this.setState({
            hit1: [hitstate[0], hitstate[1], false, hitstate[3], hitstate[4]]
          }, modedCallback);
          break;
        case "Pat":
          this.setState({
            pat1: [patstate[0], patstate[1], false, patstate[3], patstate[4]]
          }, modedCallback);
          break;
        case "Push":
          this.setState({
            push1: [pushstate[0], pushstate[1], false, pushstate[3], pushstate[4]]
          }, modedCallback);
          break;
        case "Rub":
          this.setState({
            rub1: [rubstate[0], rubstate[1], false, rubstate[3], rubstate[4]]
          }, modedCallback);
          break;
        case "Shake":
          this.setState({
            shake1: [shakestate[0], shakestate[1], false, shakestate[3], shakestate[4]]
          }, modedCallback);
          break;
        case "Squeeze":
          this.setState({
            squeeze1: [squeezestate[0], squeezestate[1], false, squeezestate[3], squeezestate[4]]
          }, modedCallback);
          break;
        case "Stroke":
          this.setState({
            stroke1: [strokestate[0], strokestate[1], false, strokestate[3], strokestate[4]]
          }, modedCallback);
          break;
        case "Tap":
          this.setState({
            tap1: [tapstate[0], tapstate[1], false, tapstate[3], tapstate[4]]
          }, modedCallback);
          break;
        default:
          console.log("activate: defaultState", name);
      }
      toast(name + " was deactivated!");
    } else {
      modedCallback();
    }
}

emotionSelect(name, callback = () => {}){
this.setState({
  eselected: true
});
let tname = this.state.tname;
switch (name) {
  case "happiness":
    this.setState({
      eicon: happiness,
      ename: "Happiness",
      shake: null,
      squeeze: null,
      hit: null,
      pat: null,
      push: null,
      rub: null,
      stroke: null,
      tap: null,
    }, callback);
    if (tname != null) {
      this.tactorSelect(tname.toLowerCase(), "happiness");
    }
    break;
  case "fear":
    this.setState({
      eicon: fear,
      ename: "Fear",
      shake: null,
      squeeze: null,
      hit: null,
      pat: null,
      push: null,
      rub: null,
      stroke: null,
      tap: null,
    }, callback);
    if (tname != null) {
      this.tactorSelect(tname.toLowerCase(), "fear");
    }
    break;
  case "sympathy":
    this.setState({
      eicon: sympathy,
      ename: "Sympathy",
      shake: null,
      squeeze: null,
      hit: null,
      pat: null,
      push: null,
      rub: null,
      stroke: null,
      tap: null,
    }, callback);
    if (tname != null) {
      this.tactorSelect(tname.toLowerCase(), "sympathy");
    }
    break;
  case "sadness":
    this.setState({
      eicon: sadness,
      ename: "Sadness",
      shake: null,
      squeeze: null,
      hit: null,
      pat: null,
      push: null,
      rub: null,
      stroke: null,
      tap: null,
    }, callback);
    if (tname != null) {
      this.tactorSelect(tname.toLowerCase(), "sadness");
    }
    break;
  case "anger":
    this.setState({
      eicon: anger,
      ename: "Anger",
      shake: null,
      squeeze: null,
      hit: null,
      pat: null,
      push: null,
      rub: null,
      stroke: null,
      tap: null,
    }, callback);
    if (tname != null) {
      this.tactorSelect(tname.toLowerCase(), "anger");
    }
    break;
  case "disgust":
    this.setState({
      eicon: disgust,
      ename: "Disgust",
      shake: null,
      squeeze: null,
      hit: null,
      pat: null,
      push: null,
      rub: null,
      stroke: null,
      tap: null,
    }, callback);
    if (tname != null) {
      this.tactorSelect(tname.toLowerCase(), "disgust");
    }
    break;
  case "love":
    this.setState({
      eicon: love,
      ename: "Love",
      shake: null,
      squeeze: null,
      hit: null,
      pat: null,
      push: null,
      rub: null,
      stroke: null,
      tap: null,
    }, callback);
    if (tname != null) {
      this.tactorSelect(tname.toLowerCase(), "love");
    }
    break;
  case "gratitude":
    this.setState({
      eicon: gratitude,
      ename: "Gratitude",
      shake: null,
      squeeze: null,
      hit: null,
      pat: null,
      push: null,
      rub: null,
      stroke: null,
      tap: null,
    }, callback);
    if (tname != null) {
      this.tactorSelect(tname.toLowerCase(), "gratitude");
    }
    break;
  case "otherEmotion":
    this.setState({
      eicon: gratitude,
      ename: "Other",
      shake: null,
      squeeze: null,
      hit: null,
      pat: null,
      push: null,
      rub: null,
      stroke: null,
      tap: null,
    }, callback);
    if (tname != null) {
      this.tactorSelect(tname.toLowerCase(), "otherEmotion");
    }
    break;
  default:
    console.log("emotionSelect: defaultState", name);
}
}

tactorSelect(name, ename, callback = () => {}) {

  this.setState({
    tselected: true
  });

  switch (name.toLowerCase()) {

    case "shake":
      this.serialmsg[0] = 3;
      this.setState({
        ticon: shake,
        tname: "Shake",
        happiness: null,
        fear: null,
        sympathy: null,
        sadness: null,
        anger: null,
        disgust: null,
        love: null,
        gratitude: null,
        otherEmotion: null,
      });
      this.setState({
        trange: [80, 90, 75, 105],
        tspeed: [100, 0, 0],
        rspeed: [100, 0, 0],
        randomness: [0, 0, 0],
        tint: [0, 0, 0],
        irand: [0, 0, 0],
      }, callback);
      break;
    case "squeeze":
      this.serialmsg[0] = 4;
      this.setState({
        ticon: squeeze,
        tname: "Squeeze",
        happiness: null,
        fear: null,
        sympathy: null,
        sadness: null,
        anger: null,
        disgust: null,
        love: null,
        gratitude: null,
        otherEmotion: null,
      });
      this.setState({
        trange: [60, 90, 50, 140],
        tspeed: [100, 0, 0],
        rspeed: [100, 0, 0],
        randomness: [0, 0, 0],
        tint: [0, 0, 0],
        irand: [0, 0, 0],
      }, callback);
      break;
    case "hit":
      this.serialmsg[0] = 0;
      this.setState({
        ticon: hit,
        tname: "Hit",
        happiness: null,
        fear: null,
        sympathy: null,
        sadness: null,
        anger: null,
        disgust: null,
        love: null,
        gratitude: null,
        otherEmotion: null,
      });
      this.setState({
        trange: [45, 90, 50, 120],
        tspeed: [100, 0, 0],
        rspeed: [100, 0, 0],
        randomness: [0, 0, 0],
        tint: [0, 0, 0],
        irand: [0, 0, 0],
      }, callback);
      break;
    case "pat":
      this.serialmsg[0] = 0;
      this.setState({
        ticon: pat,
        tname: "Pat",
        happiness: null,
        fear: null,
        sympathy: null,
        sadness: null,
        anger: null,
        disgust: null,
        love: null,
        gratitude: null,
        otherEmotion: null,
      });
      this.setState({
        trange: [60, 90, 50, 120],
        tspeed: [100, 0, 0],
        rspeed: [100, 0, 0],
        randomness: [0, 0, 0],
        tint: [0, 0, 0],
        irand: [0, 0, 0],
      }, callback);
      break;
    case "push":
      this.serialmsg[0] = 1;
      this.setState({
        ticon: push,
        tname: "Push",
        happiness: null,
        fear: null,
        sympathy: null,
        sadness: null,
        anger: null,
        disgust: null,
        love: null,
        gratitude: null,
        otherEmotion: null,
      });
      this.setState({
        trange: [20, 30, 10, 40],
        tspeed: [100, 0, 0],
        rspeed: [100, 0, 0],
        randomness: [0, 0, 0],
        tint: [0, 0, 0],
        irand: [0, 0, 0],
      }, callback);
      break;
    case "rub":
      this.serialmsg[0] = 2;
      this.setState({
        ticon: rub,
        tname: "Rub",
        happiness: null,
        fear: null,
        sympathy: null,
        sadness: null,
        anger: null,
        disgust: null,
        love: null,
        gratitude: null,
        otherEmotion: null,
      });
      this.setState({
        trange: [30, 60, 0, 180],
        tspeed: [100, 0, 0],
        rspeed: [100, 0, 0],
        randomness: [0, 0, 0],
        tint: [0, 0, 0],
        irand: [0, 0, 0],
      }, callback);
      break;
    case "stroke":
      this.serialmsg[0] = 5;
      this.setState({
        ticon: stroke,
        tname: "Stroke",
        happiness: null,
        fear: null,
        sympathy: null,
        sadness: null,
        anger: null,
        disgust: null,
        love: null,
        gratitude: null,
        otherEmotion: null,
      });
      this.setState({
        trange: [30, 60, 0, 180],
        tspeed: [100, 0, 0],
        rspeed: [100, 0, 0],
        randomness: [0, 0, 0],
        tint: [0, 0, 0],
        irand: [0, 0, 0],
      }, callback);
      break;
    case "tap":
      this.serialmsg[0] = 6;
      this.setState({
        ticon: tap,
        tname: "Tap",
        happiness: null,
        fear: null,
        sympathy: null,
        sadness: null,
        anger: null,
        disgust: null,
        love: null,
        gratitude: null,
        otherEmotion: null,
      });
      this.setState({
        trange: [75, 85, 70, 110],
        tspeed: [100, 0, 0],
        rspeed: [100, 0, 0],
        randomness: [0, 0, 0],
        tint: [0, 0, 0],
        irand: [0, 0, 0],
      }, callback);
      break;
    default:
      console.log("tactorSelect: defaultState", name);
  }
  this.serialmsg[1] = this.state.trange[0]; //range min
  this.serialmsg[2] = this.state.trange[1]; //range max
}

bitshift(number){
  let data=new Uint8Array(2);
  data[0] = number & 0xFF;
  data[1] = (number >> 8) & 0xFF;
  let reverse=data.join();
  reverse = reverse.replace(',','')
  console.log(parseInt(reverse,reverse.length));
  return data;
}

handleSlider(name, data){
  // console.log(data);
  switch (name) {
    case "trange":
      let tranger = [this.state.trange];
    
      tranger[0] = data.from;
      tranger[1] = data.to;
      this.setState({trange: tranger});
      this.serialmsg[1] = tranger[0]; //range min
      this.serialmsg[2] = tranger[1]; //range max
      break;
    case "tspeed":
      let tspeeder = [this.state.tspeed];
      tspeeder[0] = data.from;
      this.setState({tspeed: tspeeder});
      // this.serialmsg[3] = ((this.state.trange[1] - this.state.trange[0])/tspeeder[0]); //touch duration
      this.serialmsg[3] = tspeeder[0]; // time of retreat
      break;
    case "rspeed":
      let rspeeder = [this.state.rspeed];
      rspeeder[0] = data.from;
      this.setState({rspeed: rspeeder});
      // this.serialmsg[4] = ((this.state.trange[1] - this.state.trange[0])/rspeeder[0]); //retreat duration
      this.serialmsg[4] = rspeeder[0]; // time of retreat
      break;
    case "randomness":
      let rander = [this.state.randomness];
      rander[0] = data.from;
      this.setState({randomness: rander});
      this.serialmsg[5] = rander[0]; //touch_randomness
      break;
    case "tint":
      let tinter = [this.state.tint];
      tinter[0] = data.from;
      this.setState({tint:tinter});
      this.serialmsg[6] = tinter[0]; //gap duration
      break;
    case "irand":
      let irander = [this.state.irand];
      irander[0] = data.from;
      this.setState({irand:irander});
      this.serialmsg[7] = irander[0]; //contact duration
      break;
      default: console.log("handleSlider: defaultState", name);
  }
  // console.log(this.serialmsg);
  this.write(this.serialmsg);

}

pp(play) {
  if (this.state.tselected) {
    if (play) {
      this.serialmsg[8] = 1;
    } else {
      this.serialmsg[8] = 0;
    }
    this.write(this.serialmsg);
  } else {
    toast("Tactor not selected, you must select a tactor before uploading tactor behavior.");
  }

  //this.start(this.serialmsg);
}

componentDidMount(){
    this.getArchiveData();
}

getArchiveData(){
  return fetch(`${backendURL}/archive/${this.state.username}`)
  .then(response => response.json())
  .then(data => {
    if (Array.isArray(data.results)) {
      this.setState({ archiveData: data.results});
    } else {
      this.setState({ archiveData: []});
    }
  });
}

deleteFromArchive(item){
  if(window.confirm('Are you sure you want to delete - ' + item.name + '?')){
    return fetch(`${backendURL}/archive/${this.state.username}/${item.id}/delete`)
    .then(response => response.json())
    .then(data => {
      if(data.response === "success"){
        toast(({ closeToast }) => <div>Deleted [<b>{item.name}</b>]</div>);
        this.getArchiveData();
      } else {
        toast(({ closeToast }) => <div>ERROR: Couldn't delete [<b>{item.name}</b>]</div>);
      }
    })
  }
}

setFromArchive(item){
  let routine = () => {
    this.emotionSelect(item.emotion.toLowerCase(), () => {
      this.tactorSelect(item.tactor.toLowerCase(), this.state.ename, () => {

        let params = item.params;

        let tranger = this.state.trange;
        tranger[0] = params.touch_range_min;
        tranger[1] = params.touch_range_max;

        this.serialmsg[1] = tranger[0]; //range min
        this.serialmsg[2] = tranger[1]; //range max

        let tspeeder = this.state.tspeed;
        tspeeder[0] = params.touch_speed;
        this.serialmsg[3] = tspeeder[0]; // time of retreat

        let rspeeder = this.state.rspeed;
        rspeeder[0] = params.retreat_speed;
        this.serialmsg[4] = rspeeder[0]; // time of retreat

        let rander = this.state.randomness;
        rander[0] = params.touch_random;
        this.serialmsg[5] = rander[0]; //touch_randomness

        let tinter = this.state.tint;
        tinter[0] = params.touch_interval;
        this.serialmsg[6] = tinter[0]; //gap duration

        let irander = this.state.irand;
        irander[0] = params.interval_random;
        this.serialmsg[7] = irander[0]; //contact duration
        
        this.setState({
          trange: tranger,
          tspeed: tspeeder,
          rspeed: rspeeder,
          randomness: rander,
          tint: tinter,
          irand: irander,
          fileName: item.name
        }, () => {
          this.handleClick("tgen");
          this.handleClick("behavior");
        });
      });
    }).then(()=>{this.forceUpdate();});
  }

  if (this.isActive(item.tactor)) {
    routine();
  } else {
    if (window.confirm("The required tactor is not activated.\nDo you want to activate it now?")) {
      this.handleClick("tinfo");
      this.handleClick(item.tactor.toLowerCase() + '1');
    } else {
      routine();
    }
  }
}

render(){
  return(
    <div>
    <nav className="navbar top-nav navbar-expand navbar-light bg-white ">
      <h6 className="navbar-brand" onClick={() => this.handleClick("tbots")}>TactorBots</h6>

      <div className="navbar-collapse" id="navbarSupportedContent">
        <ul className="top-nav navbar-nav mr-auto ml-auto d-flex align-content-center">
          <li className={"top-nav nav-item active text-center "+ this.state.generator[1]}>
            <a className= {"top-nav nav-link " + this.state.generator[1]} onClick={() => this.handleClick("tgen")}>Touch Generator{this.state.generator[0]&&(<span className="sr-only">(current)</span>)}</a>
          </li>
          <li className={"top-nav nav-item active text-center "+ this.state.tinfo[1]}>
            <a className={"top-nav nav-link " + this.state.tinfo[1]} onClick={() => this.handleClick("tinfo")}>Tactor Information{this.state.tinfo[0]&&(<span className="sr-only">(current)</span>)}</a>
          </li>
          <li className={"top-nav nav-item active text-center "+ this.state.connection[1]}>
            <a className={"top-nav nav-link " + this.state.connection[1]} onClick={() => this.handleClick("connection")}>Connection{this.state.connection[0]&&(<span className="sr-only">(current)</span>)}</a>
          </li>
          <li className={"top-nav nav-item active text-center "+ this.state.archive[1]}>
            <a className={"top-nav nav-link " + this.state.archive[1]} onClick={() => this.handleClick("archive")}>Archive{this.state.archive[0]&&(<span className="sr-only">(current)</span>)}</a>
          </li>
        </ul>
        <div className="username">{this.state.username} <img src={edit} alt="" className="img-button" onClick={() => this.handleClick("login")}/></div>
      </div>
    </nav>

    <section className="page-content">
        <main role="main page">

      {this.state.tinfo[0] &&(
        <div className="row left-nav group1 tinfo">
          <div className="col-2 bg-light  sidebar d-flex tinfo">
            <div className ="row left-nav group1">
              <div className="col-12 left-col">
                <nav className="navbar left-nav navbar-expand navbar-light">
                  <ul className="left-nav text-left tinfo">
                    <li className={"left-nav tactorinfonav nav-link col col-auto tactorlink nav-link " + this.state.hit1[1]} onClick={() => this.handleClick("hit1")}>
                    <div className="row flex-nowrap">
                      <div className="col">
                        <div className={"tactoricon d-flex justify-content-start " + this.state.hit1[1]}>
                          <img src={this.state.hit1[0] ? hitw  : hit} alt="" className="tactorinfoicon"/>
                        </div>
                      </div>
                      <div className="col tactinfo">
                        <h4 className="tactinfo">Hit</h4>
                        <br/>
                        <h5 className="tactinfo">Tactor 1</h5><img src={link} alt="" className="tactinfo"/><h5 className="tactinfo"> Port 1</h5>
                      </div>
                    </div>
                    </li>
                    <li className={"left-nav tactorinfonav nav-link col col-auto tactorlink nav-link " + this.state.pat1[1]}  onClick={() => this.handleClick("pat1")}>
                      <div className="row flex-nowrap">
                        <div className="col">
                          <div className={"tactoricon d-flex justify-content-start " + this.state.pat1[1]}>
                            <img src={this.state.pat1[0] ? patw  : pat} alt="" className="tactorinfoicon"/>
                          </div>
                        </div>
                        <div className="col tactinfo">
                          <h4 className="tactinfo">Pat</h4>
                          <br/>
                          <h5 className="tactinfo">Tactor 1</h5><img src={link} alt="" className="tactinfo"/><h5 className="tactinfo"> Port 1</h5>
                        </div>
                      </div>
                    </li>
                    <li className={"left-nav tactorinfonav nav-link col col-auto tactorlink nav-link " + this.state.push1[1]}  onClick={() => this.handleClick("push1")}>
                      <div className="row flex-nowrap">
                        <div className="col">
                          <div className={"tactoricon d-flex justify-content-start " + this.state.push1[1]}>
                            <img src={this.state.push1[0] ? pushw  : push} alt="" className="tactorinfoicon"/>
                          </div>
                        </div>
                        <div className="col tactinfo">
                          <h4 className="tactinfo">Push</h4>
                          <br/>
                          <h5 className="tactinfo">Tactor 2</h5><img src={link} alt="" className="tactinfo"/><h5 className="tactinfo"> Port 2</h5>
                        </div>
                      </div>
                    </li>
                    <li className={"left-nav tactorinfonav nav-link col col-auto tactorlink nav-link " + this.state.rub1[1]}  onClick={() => this.handleClick("rub1")}>
                      <div className="row flex-nowrap">
                        <div className="col">
                          <div className={"tactoricon d-flex justify-content-start " + this.state.rub1[1]}>
                            <img src={this.state.rub1[0] ? rubw  : rub} alt="" className="tactorinfoicon"/>
                          </div>
                        </div>
                        <div className="col tactinfo">
                          <h4 className="tactinfo">Rub</h4>
                          <br/>
                          <h5 className="tactinfo">Tactor 3</h5><img src={link} alt="" className="tactinfo"/><h5 className="tactinfo"> Port 3</h5>
                        </div>
                      </div>
                    </li>
                    <li className={"left-nav tactorinfonav nav-link col col-auto tactorlink nav-link " + this.state.shake1[1]}  onClick={() => this.handleClick("shake1")}>
                      <div className="row flex-nowrap">
                        <div className="col">
                          <div className={"tactoricon d-flex justify-content-start " + this.state.shake1[1]}>
                            <img src={this.state.shake1[0] ? shakew  : shake} alt="" className="tactorinfoicon"/>
                          </div>
                        </div>
                        <div className="col tactinfo">
                          <h4 className="tactinfo">Shake</h4>
                          <br/>
                          <h5 className="tactinfo">Tactor 4</h5><img src={link} alt="" className="tactinfo"/><h5 className="tactinfo"> Port 4</h5>
                        </div>
                      </div>
                    </li>
                    <li className={"left-nav tactorinfonav nav-link col col-auto tactorlink nav-link " + this.state.squeeze1[1]}  onClick={() => this.handleClick("squeeze1")}>
                      <div className="row flex-nowrap">
                        <div className="col">
                          <div className={"tactoricon d-flex justify-content-start " + this.state.squeeze1[1]}>
                            <img src={this.state.squeeze1[0] ? squeezew  : squeeze} alt="" className="tactorinfoicon"/>
                          </div>
                        </div>
                        <div className="col tactinfo">
                          <h4 className="tactinfo">Squeeze</h4>
                          <br/>
                          <h5 className="tactinfo">Tactor 5</h5><img src={link} alt="" className="tactinfo"/><h5 className="tactinfo"> Port 5</h5>
                        </div>
                      </div>
                    </li>
                    <li className={"left-nav tactorinfonav nav-link col col-auto tactorlink nav-link " + this.state.stroke1[1]}  onClick={() => this.handleClick("stroke1")}>
                      <div className="row flex-nowrap">
                        <div className="col">
                          <div className={"tactoricon d-flex justify-content-start " + this.state.stroke1[1]}>
                            <img src={this.state.stroke1[0] ? strokew  : stroke} alt="" className="tactorinfoicon"/>
                          </div>
                        </div>
                        <div className="col tactinfo">
                          <h4 className="tactinfo">Stroke</h4>
                          <br/>
                          <h5 className="tactinfo">Tactor 6</h5><img src={link} alt="" className="tactinfo"/><h5 className="tactinfo"> Port 6</h5>
                        </div>
                      </div>
                    </li>
                    <li className={"left-nav tactorinfonav nav-link col col-auto tactorlink nav-link " + this.state.tap1[1]}  onClick={() => this.handleClick("tap1")}>
                      <div className="row flex-nowrap">
                        <div className="col">
                          <div className={"tactoricon d-flex justify-content-start " + this.state.tap1[1]}>
                            <img src={this.state.tap1[0] ? tapw  : tap} alt="" className="tactorinfoicon"/>
                          </div>
                        </div>
                        <div className="col tactinfo">
                          <h4 className="tactinfo">Tap</h4>
                          <br/>
                          <h5 className="tactinfo">Tactor 7</h5><img src={link} alt="" className="tactinfo"/><h5 className="tactinfo"> Port 7</h5>
                        </div>
                      </div>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
          
          
          <div className="col-10 main-content">
            <section className="main-head">

              <h2>{this.state.acttact[3]} Tactor</h2>

            </section>
            
            <section className="generator-table">
              <div className="row generator justify-content-center">
                <div className="col col-auto">
                  <div className="row">
                    <div className="col">
                  <div className="bg-light tactortop-box  d-flex align-items-center">
                  <img src={this.state.up} alt="" className="generator-logo tactortop"/>
                </div>
                </div>
              </div>
              <div className="row">
                <div className="col">
              <div className="bg-light tactorside-box  d-flex align-items-center">
              <img src={this.state.cut} alt="" className="generator-logo tactorside"/>
            </div>

              <h4 className="d-flex justify-content-center text-center mx-auto tactoroverview">Tactor Overview</h4>
            </div>
          </div>
                </div>


                <div className="col col-auto">
                  <div className="bg-light  tactorplacement-box  tactorinfo d-flex align-items-center">
                    <img src={this.state.place} alt="" className="generator-logo tactorplacement"/>
                  </div>
                  <h4 className="text-center mx-auto">Suggested Placement</h4>
                </div>

                <div className="col col-auto">
                  <div className="bg-light  tactorplacement-box  tactorinfo d-flex align-items-center">

                    {this.state.hit1[0] &&(
                      <div className="row tactor-emotion d-flex justify-items-center">
                      <div className="col col-12 tactor-emotion">
                      <img src={anger} alt="" className="tactor-emotion"/>
                      <h4 className="text-center tactor-emotion">Anger</h4>
                    </div>
                    </div>
                  )}
                  {this.state.tap1[0] &&(
                    <div className="row tactor-emotion d-flex justify-items-center">
                    <div className="col col-12 tactor-emotion">
                    <img src={gratitude} alt="" className="tactor-emotion"/>
                    <h4 className="text-center tactor-emotion">Gratitude</h4>
                  </div>
                  </div>
                )}
                {this.state.stroke1[0] &&(
                  <div className="row tactor-emotion d-flex justify-items-center">
                  <div className="col col-7 tactor-emotion mx-auto">
                  <img src={love} alt="" className="tactor-emotion"/>
                  <h4 className="text-center tactor-emotion">Love</h4>
                </div>
                  <div className="w-100"></div>
                <div className="col col-7 tactor-emotion mx-auto">
                <img src={sympathy} alt="" className="tactor-emotion"/>
                <h4 className="text-center tactor-emotion">Sympathy</h4>
              </div>
              <div className="w-100"></div>
              <div className="col col-7 tactor-emotion mx-auto">
              <img src={sadness} alt="" className="tactor-emotion"/>
              <h4 className="text-center tactor-emotion">Sadness</h4>
            </div>
            </div>
          )}{this.state.squeeze1[0] &&(
            <div className="row tactor-emotion d-flex justify-items-center">
                  <div className="col col-6 tactor-emotion">
                  <img src={fear} alt="" className="tactor-emotion"/>
                  <h4 className="text-center tactor-emotion">Fear</h4>
                </div>
                    <div className="col col-6 tactor-emotion">
                      <img src={love} alt="" className="tactor-emotion"/>
                      <h4 className="text-center tactor-emotion">Love</h4>
                    </div>
                    <div className="w-100"></div>
                    <div className="col col-6 tactor-emotion">
                    <img src={anger} alt="" className="tactor-emotion"/>
                    <h4 className="text-center tactor-emotion">Anger</h4>
                    </div>
                    <div className="col col-6 tactor-emotion">
                      <img src={happiness} alt="" className="tactor-emotion"/>
                      <h4 className="text-center tactor-emotion">Happiness</h4>
                    </div>
                    <div className="w-100"></div>
                    <div className="col col-6 tactor-emotion">
                    <img src={gratitude} alt="" className="tactor-emotion"/>
                    <h4 className="text-center tactor-emotion">Gratitude</h4>
                    </div>
                    <div className="col col-6 tactor-emotion">
                      <img src={sadness} alt="" className="tactor-emotion"/>
                      <h4 className="text-center tactor-emotion">Sadness</h4>
                    </div>
                  </div>)}
                    {this.state.shake1[0] &&(
                      <div className="row tactor-emotion d-flex justify-items-center">
                            <div className="col col-6 tactor-emotion">
                            <img src={happiness} alt="" className="tactor-emotion"/>
                            <h4 className="text-center tactor-emotion">Happiness</h4>
                          </div>
                              <div className="col col-6 tactor-emotion">
                                <img src={gratitude} alt="" className="tactor-emotion"/>
                                <h4 className="text-center tactor-emotion">Gratitude</h4>
                              </div>
                              <div className="w-100"></div>
                              <div className="col col-6 tactor-emotion">
                              <img src={love} alt="" className="tactor-emotion"/>
                              <h4 className="text-center tactor-emotion">Love</h4>
                              </div>
                              <div className="col col-6 tactor-emotion">
                                <img src={sadness} alt="" className="tactor-emotion"/>
                                <h4 className="text-center tactor-emotion">Sadness</h4>
                              </div>
                              <div className="w-100"></div>
                              <div className="col col-6 tactor-emotion">
                              <img src={sympathy} alt="" className="tactor-emotion"/>
                              <h4 className="text-center tactor-emotion">Sympathy</h4>
                              </div>
                              </div>
                            )}
                            {this.state.rub1[0] &&(
                              <div className="row tactor-emotion d-flex justify-items-center">
                              <div className="col col-7 tactor-emotion mx-auto">
                              <img src={love} alt="" className="tactor-emotion"/>
                              <h4 className="text-center tactor-emotion">Love</h4>
                            </div>
                            <div className="w-100"></div>
                            <div className="col col-7 tactor-emotion mx-auto">
                            <img src={sympathy} alt="" className="tactor-emotion"/>
                            <h4 className="text-center tactor-emotion">Sympathy</h4>
                          </div>
                          </div>
                      )}  {this.state.push1[0] &&(
                        <div className="row tactor-emotion d-flex justify-items-center">
                          <div className="col col-7 tactor-emotion mx-auto">
                          <img src={disgust} alt="" className="tactor-emotion"/>
                          <h4 className="text-center tactor-emotion">Disgust</h4>
                        </div>
                        <div className="w-100"></div>
                        <div className="col col-7 tactor-emotion mx-auto">
                        <img src={anger} alt="" className="tactor-emotion"/>
                        <h4 className="text-center tactor-emotion">Anger</h4>
                      </div>
                      <div className="w-100"></div>
                      <div className="col col-7 tactor-emotion mx-auto">
                      <img src={sadness} alt="" className="tactor-emotion"/>
                      <h4 className="text-center tactor-emotion">Sadness</h4>
                    </div>
                    </div>
                  )}
                  {this.state.pat1[0] &&(
                    <div className="row tactor-emotion d-flex justify-items-center">
                          <div className="col col-6 tactor-emotion">
                          <img src={sympathy} alt="" className="tactor-emotion"/>
                          <h4 className="text-center tactor-emotion">Sympathy</h4>
                        </div>
                            <div className="col col-6 tactor-emotion">
                              <img src={love} alt="" className="tactor-emotion"/>
                              <h4 className="text-center tactor-emotion">Love</h4>
                            </div>
                            <div className="w-100"></div>
                            <div className="col col-6 tactor-emotion">
                            <img src={gratitude} alt="" className="tactor-emotion"/>
                            <h4 className="text-center tactor-emotion">Gratitude</h4>
                            </div>

                            <div className="col col-6 tactor-emotion">
                              <img src={sadness} alt="" className="tactor-emotion "/>
                              <h4 className="text-center tactor-emotion">Sadness</h4>
                            </div>
                          </div>)}


                  </div>
                  <h4 className="text-center  mx-auto">Suggested Emotion</h4>
                </div>
              </div>
            </section>
            
            <div className="row buttons">
              <div className="col col-3 tactorinfonav d-flex align-content-end tactselected">
                <div className="row flex-nowrap">
                  <div className="col">
                    <div className="tactoricon d-flex justify-content-start">
                      <img src={this.state.acttact[0]} alt="" className="tactorinfoicon"/>
                    </div>
                  </div>
                  <div className="col tactinfo infotab">
                      <h4 className="tactinfo title">{this.state.acttact[3]}</h4>
                      <div className="tactinfo subtitle">
                        <h5>{this.state.acttact[1]} <img src={link} alt=""/> {this.state.acttact[2]}</h5>
                      </div>
                  </div>

                </div>
              </div>
              <div className="col float-right activated d-flex align-items-end justify-content-end">
                <h4 className="activated">To activate, please connect the tactor to home module and make sure the module is connected to PC.
                <br/>
                Check the <a className="connection-link" onClick={() => this.handleClick("connection")}>Connection Tab</a> if you have any questions</h4>
                
                {this.isActive(this.state.acttact[3])?
                  (<button className="align-items-center" id="deactivated"  onClick={() => this.deactivate(this.state.acttact[3])}>
                    <h6 className="text-center deactivated">Deactivate</h6>
                  </button>) : 
                  (<button className="align-items-center" id="activated"  onClick={() => this.activate(this.state.acttact[3])}>
                    <h6 className="text-center activated">Activate</h6>
                  </button>)}
              </div>
              <div className="clearfix"></div>
            </div>
          </div>
        </div>
      )}

      {this.state.generator[0] && (
        <div className ="row left-nav group1 dflex flex-nowrap">
              <div className="col col-2 bg-light  sidebar d-flex">
                <div className ="row left-nav group1">
                  <div className="col-12 left-col">
                    <nav className="navbar left-nav navbar-expand navbar-light">
                      <ul className="left-nav nav-item text-left">
                        <li className={"left-nav nav-link " + this.state.estate} onClick={() => this.handleClick("emotion")}>
                          <a className="left-nav nav-link">
                            <img src={emotion} alt="" className="left-nav icon"/>
                            Emotion
                          </a>
                        </li>

                        {this.state.eselected && (
                          <div className="row justify-content-center">
                            <div className="col col-auto bg-white sidebar-emotion-box">
                              <img src={this.state.eicon} alt="" className="emotion-logo happiness sidebar-emotion" />
                              <h4 className="text-center">{this.state.ename}</h4>
                            </div>
                          </div>
                        )}

                        <li className={"left-nav nav-link " + this.state.tstate}   onClick={() => this.handleClick("tactor")}>
                          <a className="left-nav nav-link" >
                            <img src={tactor} alt="" className="left-nav icon"/>
                            Tactor
                          </a>
                        </li>

                        {this.state.tselected && (
                        <div className="row justify-content-center">
                          <div className="col col-auto bg-white sidebar-tactor-box">
                            <img src={this.state.ticon} alt="" className="tactor-logo happiness sidebar-tactor"/>
                            <h4 className="text-center">{this.state.tname}</h4>
                          </div>
                        </div>
                        )}

                        <li className={"left-nav nav-link "+ this.state.bstate}  >
                          <a className="left-nav nav-link" onClick={() => this.handleClick("behavior")}>
                            <img src={behavior} alt="" className="left-nav icon"/>
                            Touch
                          </a>
                        </li>
                      </ul>
                    </nav>
                  </div>
                </div>


              </div>

              {this.state.home && (
              <div className="col col-10 main-content">
              <section className="main-head">


                <h2>Touch Generator</h2>
                <h3>Create a new emotional robot touch clip</h3>

              </section>
              <section className="generator-table">
                <div className="row generator justify-content-center">
                  <div className="col col-auto" >
                    <div className="bg-light touchgen-box touchgen d-flex align-items-center" onClick={() => this.handleClick("emotion")}>
                    <img src={neutral} alt="" className="generator-logo"/>
                  </div>

                    <h4 className="text-center text-uppercase mx-auto">Emotion</h4>

                  </div>
                  <div className="col col-auto d-flex align-items-center">
                    <h2 className="generator">OR</h2>
                    </div>
                  <div className="col col-auto">
                    <div className="bg-light  touchgen-box  touchgen d-flex align-items-center" onClick={() => this.handleClick("tactor")}>
                      <img src={squeeze} alt="" className="generator-logo"/>
                    </div>
                    <h4 className="text-center text-uppercase mx-auto">Tactor</h4>
                  </div>
                </div>

              </section>
              </div>
            )}

            {this.state.emotion && (
              <div className="col-10 main-content">
              <section className="main-head">


                <h2>Emotion</h2>
                <h3>Select one emotion to start</h3>

              </section>
              <section className="emotion-table">
                <div className="row emotion">
                  <div className="col">
                    <div className={"bg-light emotion-box emotion d-flex align-items-center "+ this.state.happiness} onClick={() => (this.emotionSelect("happiness"), this.handleClick("emotionselect"))}>
                    <img src={happiness} alt="" className="emotion-logo happiness"/>
                  </div>

                    <h4 className="text-center">Happiness</h4>

                  </div>
                  <div className="col">
                    <div className={"bg-light emotion-box emotion d-flex align-items-center "+ this.state.fear} onClick={() => (this.emotionSelect("fear"), this.handleClick("emotionselect"))}>
                      <img src={fear} alt="" className="emotion-logo fear"/>
                    </div>
                    <h4 className="text-center">Fear</h4>
                  </div>
                  <div className="col">
                    <div className={"bg-light emotion-box emotion d-flex align-items-center "+ this.state.sympathy} onClick={() => (this.emotionSelect("sympathy"), this.handleClick("emotionselect"))}>
                        <img src={sympathy} alt="" className="emotion-logo sympathy"/>
                    </div>
                    <h4 className="text-center">Sympathy</h4>
                  </div>
                  <div className="col">
                    <div className={"bg-light emotion-box emotion d-flex align-items-center "+ this.state.sadness}  onClick={() => (this.emotionSelect("sadness"), this.handleClick("emotionselect"))}>
                      <img src={sadness} alt="" className="emotion-logo sadness"/>
                    </div>
                    <h4 className="text-center">Sadness</h4>
                  </div>
                </div>
                <div className="row emotion">
                  <div className="col">
                    <div className={"bg-light emotion-box emotion d-flex align-items-center "+ this.state.anger} onClick={() => (this.emotionSelect("anger"), this.handleClick("emotionselect"))}>
                      <img src={anger} alt="" className="emotion-logo anger"/>
                  </div>
                  <h4 className="text-center">Anger</h4>
                  </div>
                  <div className="col">
                    <div className={"bg-light emotion-box emotion d-flex align-items-center "+ this.state.disgust} onClick={() => (this.emotionSelect("disgust"), this.handleClick("emotionselect"))}>
                      <img src={disgust} alt="" className="emotion-logo disgust"/>
                    </div>
                    <h4 className="text-center">Disgust</h4>
                  </div>
                  <div className="col">
                    <div className={"bg-light emotion-box emotion d-flex align-items-center "+ this.state.love} onClick={() => (this.emotionSelect("love"), this.handleClick("emotionselect"))}>
                      <img src={love} alt="" className="emotion-logo love"/>
                    </div>
                    <h4 className="text-center">Love</h4>
                  </div>
                  <div className="col">
                    <div className={"bg-light emotion-box emotion d-flex align-items-center "+ this.state.gratitude} onClick={() => (this.emotionSelect("gratitude"), this.handleClick("emotionselect"))}>
                      <img src={gratitude} alt="" className="emotion-logo gratitude"/>
                    </div>
                    <h4 className="text-center">Gratitude</h4>
                  </div>
                </div>
                <div className="row emotion">
                  <div className="col">
                    <div className={"bg-light emotion-box emotion d-flex align-items-center "+ this.state.gratitude} onClick={() => (this.emotionSelect("otherEmotion"), this.handleClick("emotionselect"))}>
                      <img src={emotionOther} alt="" className="emotion-logo gratitude"/>
                    </div>
                    <h4 className="text-center">Other</h4>
                  </div>
                </div>

              </section>
            </div>
            )}

            {this.state.tactor && (
              <div className="col-10 main-content">
                <section className="main-head">
                  <h2>Tactor</h2>
                  <h3>Select one tactor, recommendation is provided based on study</h3>

                </section>
                <section className="emotion-table">
                  <div className="row emotion">
                    <div className="col">
                      <div className={"bg-light emotion-box emotion d-flex align-items-center " + this.state.shake}  onClick={() => (this.tactorSelect("shake", this.state.ename), this.handleClick("shake"))}>
                      <img src={shake} alt="" className="emotion-logo happiness"/>
                    </div>

                      <h4 className="text-center">Shake</h4>

                    </div>
                    <div className="col">
                      <div className={"bg-light emotion-box emotion d-flex align-items-center " + this.state.squeeze} onClick={() => (this.tactorSelect("squeeze", this.state.ename), this.handleClick("squeeze"))}>
                        <img src={squeeze} alt="" className="emotion-logo fear"/>
                      </div>
                      <h4 className="text-center">Squeeze</h4>
                    </div>
                    <div className="col">
                      <div className={"bg-light emotion-box emotion d-flex align-items-center " + this.state.hit}  onClick={() => (this.tactorSelect("hit", this.state.ename), this.handleClick("hit"))}>
                          <img src={hit} alt="" className="emotion-logo sympathy"/>
                      </div>
                      <h4 className="text-center">Hit</h4>
                    </div>
                    <div className="col">
                      <div className={"bg-light emotion-box emotion d-flex align-items-center " + this.state.pat}  onClick={() => (this.tactorSelect("pat", this.state.ename), this.handleClick("pat"))}>
                        <img src={pat} alt="" className="emotion-logo sadness"/>
                      </div>
                      <h4 className="text-center">Pat</h4>
                    </div>
                  </div>
                  <div className="row emotion">
                    <div className="col">
                      <div className={"bg-light emotion-box emotion d-flex align-items-center " + this.state.push}  onClick={() => (this.tactorSelect("push", this.state.ename), this.handleClick("push"))}>
                        <img src={push} alt="" className="emotion-logo anger"/>
                    </div>
                    <h4 className="text-center">Push</h4>
                    </div>
                    <div className="col">
                      <div className={"bg-light emotion-box emotion d-flex align-items-center " + this.state.rub}  onClick={() => (this.tactorSelect("rub", this.state.ename), this.handleClick("rub"))}>
                        <img src={rub} alt="" className="emotion-logo disgust"/>
                      </div>
                      <h4 className="text-center">Rub</h4>
                    </div>
                    <div className="col">
                      <div className={"bg-light emotion-box emotion d-flex align-items-center " + this.state.stroke}  onClick={() => (this.tactorSelect("stroke", this.state.ename), this.handleClick("stroke"))}>
                        <img src={stroke} alt="" className="emotion-logo love"/>
                      </div>
                      <h4 className="text-center">Stroke</h4>
                    </div>
                    <div className="col">
                      <div className={"bg-light emotion-box emotion d-flex align-items-center " + this.state.tap}  onClick={() => (this.tactorSelect("tap", this.state.ename), this.handleClick("tap"))}>
                        <img src={tap} alt="" className="emotion-logo gratitude"/>
                      </div>
                      <h4 className="text-center">Tap</h4>
                    </div>
                  </div>
                </section>
              </div>
            )}

            {this.state.behavior && (
              <div className="col-10 main-content">
                <section className="main-head">
                  <h2>Touch</h2>
                  <h3>Default setting recommended range are showed in the dashboard</h3>
                </section>
                <section className="touch-config">
                  <div className="row touch">
                    <div className="col">
                      <div className="bg-light dashboard-box">
                          <h4 className="dashboard">Dashboard</h4>

                          <div className="slider-div">
                            <img src={trange} alt="" className="slider-icon"/>
                            <h6 className="slider-label">Touch Range</h6>
                            <h6 className="slider-value float-right">{this.state.trange[0]} - {this.state.trange[1]} degrees</h6>
                            
                            <IonRangeSlider id="touch-range" name="touch-range" type="double" min="0" max="180" from={this.state.trange[0]} to={this.state.trange[1]} from_min={this.state.trange[2]} from_max={this.state.trange[3]} to_min={this.state.trange[2]} to_max={this.state.trange[3]} step="1" min_interval="1" keyboard="false" skin="flat" grid="true" grid_num="18" hide_min_max="true" hide_from_to="true" from_shadow="true" to_shadow="true" onFinish={(data) => (this.handleSlider("trange", data))}/>

                          </div>

                          <div className="slider-div">
                          <img src={tspeed} alt="" className="slider-icon"/>
                            <h6 className="slider-label">Time of Touching</h6>
                            <h6 className="slider-value float-right">{this.state.tspeed[0]} ms</h6>
                            
                            <IonRangeSlider id="speed-touch" name="speed-touch" type="single" min="0" max="2000" from={this.state.tspeed[0]} step="25" skin="flat" keyboard="false" grid="true" hide_min_max="true" hide_from_to="true" onFinish={(data) => (this.handleSlider("tspeed", data))}/>
                            
                          </div>

                          <div className="slider-div">
                            <img src={rspeed} alt="" className="slider-icon"/>
                            <h6 className="slider-label">Time of Retreating</h6>
                            <h6 className="slider-value float-right">{this.state.rspeed[0]} ms</h6>
                            
                            <IonRangeSlider id="speed-retreat" name="speed-retreat" type="single" min="0" max="2000" from={this.state.rspeed[0]} step="25" skin="flat" keyboard="false" grid="true" hide_min_max="true" hide_from_to="true" onFinish={(data) => (this.handleSlider("rspeed", data))}/>
                          </div>
                          

                          <div className="slider-div">
                            <img src={randomness} alt="" className="slider-icon"/>
                            <h6 className="slider-label">Touch Randomness (Tremble)</h6>
                            <h6 className="slider-value float-right">{this.state.randomness[0]}</h6>

                            <IonRangeSlider id="touch-randomness" name="touch-randomness" type="single" min="0" max="100" from={this.state.randomness[0]} step="5" skin="flat" keyboard="false" grid="true" hide_min_max="true" hide_from_to="true" onFinish={(data) => (this.handleSlider("randomness", data))}/>

                          </div>

                          <div className="slider-div">
                            <img src={tint} alt="" className="slider-icon"/>
                            <h6 className="slider-label">Contact Duration</h6>
                            <h6 className="slider-value float-right">{this.state.irand[0]} ms</h6>
                            
                            <IonRangeSlider id="interval-randomness" name="interval-randomness" type="single" min="0" max="2000" from={this.state.irand[0]} step="5" skin="flat" keyboard="false" grid="true" hide_min_max="true" hide_from_to="true" onFinish={(data) => (this.handleSlider("irand", data))}/>

                          </div>

                          <div className="slider-div">
                            <img src={tint} alt="" className="slider-icon"/>
                            <h6 className="slider-label">Touch Interval</h6>
                            <h6 className="slider-value float-right">{this.state.tint[0]} ms</h6>
                            
                            <IonRangeSlider id="touch-interval" name="touch-interval" type="single" min="0" max="2000" from={this.state.tint[0]} step="10" skin="flat" keyboard="false" grid="true" hide_min_max="true" hide_from_to="true" onFinish={(data) => (this.handleSlider("tint", data))}/>
                          </div>
                    </div>

                    </div>
                    <div className="col">
                      <div className="bg-light  dashboard-box">
                          <h4 className="">Motion (degree/s)</h4>
                            <div className="graph">
                            <Sketch
                    					setup={(p5, parentRef) => {
                    						p5.createCanvas(550, 200).parent(parentRef);

                    					}}
                    					draw={p5 => {
                    						p5.background("#f8f9fa");
                    						p5.fill(255, this.y * 1.3, 0);
                                let minTouchRange = this.state.trange[0];
                                let maxTouchRange = this.state.trange[1];
                                let speedOfTouching = this.state.tspeed[0];
                                let speedOfRetreating = this.state.rspeed[0];
                                let touchRandomness = this.state.randomness[0];
                                let touchInverval =this.state.tint[0];
                                let intervalRandomness = this.state.irand[0];
                                
                                if(!this.from){
                                  if (this.count <= minTouchRange){
                                    this.angle = this.count + p5.random(touchRandomness);
                                    this.timer = p5.millis();
                                  } else {

                                    let delay = touchInverval + p5.random(intervalRandomness);
                                    if((p5.millis()-this.timer)>delay){
                                      this.count = 0;
                                      this.from = true;
                                    }
                                  }
                                  this.count = this.count + speedOfTouching * 0.001*60;
                                }

                                if(this.from){

                                  if (this.count <= minTouchRange){
                                    this.angle = p5.abs(maxTouchRange -(this.count + p5.random(touchRandomness)));
                                    this.timer = p5.millis();
                                  } else {
                                      this.count = 0;
                                      this.from = false;
                                  }
                                  this.count = this.count + speedOfRetreating * 0.001*60;
                                }

                                p5.push();
                                p5.stroke(220);
                                p5.strokeWeight(2);
                                p5.noFill();
                                p5.rect(0, 0, 550, 200);
                                p5.pop();
                                var vol = this.angle;
                                this.volhistory.push(vol);
                                p5.stroke(50);

                                p5.noFill();
                                p5.push();
                                p5.strokeWeight(2);
                                p5.translate(0, 40);
                                p5.beginShape();
                                for (var i = 0; i < this.volhistory.length; i += 4) {
                                  var y = p5.map(this.volhistory[i], 0, 180, 150, 0);
                                  p5.vertex(i, y);
                                }
                                p5.endShape();
                                p5.pop();
                                if (this.volhistory.length > 540) {
                                  this.volhistory.splice(0, 4);
                                }
                                this.x = this.x+1;
                    					}}
                    				/>
                            </div>
                            <div className="d-flex justify-content-center pp">
                              <img src={play} alt="" id="play-icon" onClick={()=> (this.pp(true))} />
                              <img src={pause} alt="" id="pause-icon" onClick={()=> (this.pp(false))}/>
                            </div>
                      </div>
                      <div className="name-input">
                        <h6 className="name-label">Name</h6>
                        <input type="text" className="text-input" value={this.state.fileName} onChange={this.handleChange}/>
                        <button className="archive float-right">
                        <h6 className="archive slider-label" onClick={()=>this.handleClick("archive")}>Refer to Archive</h6>
                        <img src={arrow} alt="" className="archive" onClick={()=>this.handleClick("archive")}/>
                      </button>
                      </div>
                      <div className="buttons" id="archive-functions">
                        
                        <button className="float-right button-white" id="save" onClick={()=>this.handleClick("save2archive")}>
                          <h6>Save</h6>
                        </button>
                        
                        <button className="float-right button-black" id="export" onClick={()=>toast("Feature not active")}>
                          <h6>Export</h6>
                        </button>

                      </div>
                    </div>

                  </div>
                  </section>

                </div>
            )}

            </div>
      )}

      {this.state.connection[0] && (
        <div className ="row left-nav group1 dflex flex-nowrap">
              <div className="col col-2 bg-light  sidebar d-flex">
                <div className ="row left-nav group1">
                  <div className="col-12 left-col">
                    <nav className="navbar left-nav navbar-expand navbar-light">
                      <ul className="left-nav nav-item text-left">
                        <li className={"left-nav nav-link " + this.state.hcstate}    onClick={() => this.handleClick("homeConnect")}>
                          <a className="left-nav nav-link">
                            <img src={homeconnect} alt="" className="left-nav icon"/>
                            Home Connection
                          </a>
                        </li>

                        <li className={"left-nav nav-link " + this.state.scstate}   onClick={() => this.handleClick("serialConnect")}>
                          <a className="left-nav nav-link" >
                            <img src={serialconnect} alt="" className="left-nav icon"/>
                            Serial Connection
                          </a>
                        </li>

                        <li className={"left-nav nav-link " + (this.state.tcstate1 + " " + this.state.tcstate2)}  >
                          <a className="left-nav nav-link" onClick={() => this.handleClick("tactorConnect")}>
                            <img src={icon_tactorconnect} alt="" className="left-nav icon"/>
                            Tactor Connection
                          </a>
                        </li>
                      </ul>
                    </nav>
                  </div>
                </div>
              </div>

            {this.state.homeconnect && (
              <div className="col-10 main-content">
              <section className="main-head">
                <h2>Home Connection</h2>
              </section>
              <section className="home-connect-table">
                <div className="row home-connect-row">
                  <div className="col">
                    <div className="col-header">
                      <h3 className="home-connect">Step 1: Connect to PC</h3>
                    </div>
                    <div className={"bg-light home-connect-box home-connect d-flex align-items-center"}>
                      <img src={homeconnect_pc} alt="" className="center-image"/>
                    </div>
                    <div className="col-footer">
                      <h4 className="home-connect text-center">Connect the home module to the PC</h4>
                    </div>
                  </div>
                  <div className="col">
                    <div className="col-header">
                      <h3 className="home-connect">Step 2: Connect to external power supply</h3>
                    </div>
                    <div className={"bg-light home-connect-box home-connect d-flex align-items-center "}>
                      <img src={homeconnect_power} alt="" className="center-image"/>
                    </div>
                    <div className="col-footer">
                      <h4 className="home-connect text-center">Connect the home device to the extra power<br/>(optional when running less then 4 tactors at a time) </h4>
                    </div>
                  </div>
                </div>
                <div className="row home-connect-row">
                  <div className="col">
                    <button className="align-items-center connection-button" onClick={() => this.handleClick("serialConnect")}>
                        <h6 className="text-center">Next</h6>
                    </button>
                  </div>
                </div>
                
              </section>
            </div>
            )}

            {this.state.serialconnect && (
              <div className="col-10 main-content">
                <section className="main-head">
                  <h2>Serial Connection </h2>
                </section>
                <section>
                  {/* <h3 className="serial-connect">Select Serial Port</h3> */}
                  {/* <div className="serial-select">
                    <select id="serial-port" className="serial-select" onClick={this.handleClick("selectPort")}>
                      <option value="" disabled selected>Select Serial Port</option>
                    </select>
                  </div> */}
                  
                  <div className="centered-box">
                  {!this.state.port  && (
                    <button className="align-items-center serial-connect-button" onClick={() => this.handleClick("selectPort")}>
                        <h6 className="text-center">Select Serial Port</h6>
                    </button>
                  )}
                  {this.state.port && (
                    
                    <button className="align-items-center connection-button" onClick={() => this.handleClick("tactorConnect")}>
                        <h6 className="text-center">Next</h6>
                    </button>
                  )}
                  </div>
                </section>
              </div>
            )}

            {this.state.tactorconnect && this.state.tcstate1 && (
              <div className="col-10 main-content">
                <section className="main-head">
                  <h2>Tactor Connection</h2>
                </section>
                <section>
                  <div className="tactor1-connect-row-subtitle">Step 1: Find corresponding port for your tactor</div>
                  <div className="row tactor1-connect-row">
                    <div className="col-3">
                      <div class="sub-row">
                        <div className="col">
                          <div className="tactor-tip"><b>Tip:</b><br/>
                          If you have informed the App with the tactor you intend to activate, you will see the led light up for the target port.
                          <img src={tactortip1} className="tactor-tip-image" alt=""/>
                          </div>
                        </div>
                      </div>
                      <div class="sub-row">
                        <div className="col">
                          <div className="tactor-tip btn-spacing"><b>Tip:</b><br/>
                            For more details about the Tactors, you can refer to Tactor Information tab.<br/>
                            <button className="tip-button" onClick={() => this.handleClick("tinfo")}>Go</button>
                          </div>
                        </div>
                      </div>
                      <button className="align-items-center connection-button" onClick={() => this.handleClick("tactorConnect2")}>
                        <h6 className="text-center">Next</h6>
                    </button>
                    </div>
                    <div className="col-8">
                      <div class="sub-row no-border">
                        <div className="col">
                          <img src={tactorports} className="tactor-tip-image" alt=""/>
                        </div>
                      </div>
                    </div>
                  </div>
                  
                </section>

              </div>
            )}

            {this.state.tactorconnect && this.state.tcstate2 && (
              <div className="col-10 main-content">
                <section className="main-head">
                  <h2>Tactor Connection</h2>
                </section>
                <section>
                  <div className="tactor1-connect-row-subtitle">Step 2: Connect the tactor to the home module</div>
                  <div className="row tactor1-connect-row">
                    <div className="col-5">
                      <figure>
                        <img src={tcfig1} className="fig-border tactor-tip-image" alt=""/>
                        <figcaption>Connect the tactor to the corresponding port</figcaption>
                      </figure>
                    </div>
                    <div className="col-4">
                      <figure>
                        <img src={tcfig2} className="fig-border tactor-tip-image" alt=""/>
                        <figcaption>Take off the connector with pry tool</figcaption>
                      </figure>
                    </div>
                  </div>
                  <div className="row tactor1-connect-row">
                    <div className="col-10">
                      <figure>
                        <img src={tcfig3} className="fig-border tactor-tip-image" alt=""/>
                        <figcaption>Add an extension servo wire in the middle when a longer wire is needed</figcaption>
                      </figure>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col">
                      <button className="align-items-center tactor-nav-button" onClick={() => this.handleClick("tactorConnect")}>
                          <h6 className="text-center">Previous</h6>
                      </button>
                    </div>
                  </div>
                </section>

              </div>
            )}

            </div>
      )}

      {this.state.archive[0] && (
        <div className="row left-nav group1">

          <div className="col-2 bg-light sidebar d-flex tinfo">
            <div className ="row left-nav group1">
              <div className="col-12 left-col">
                <nav className="navbar left-nav navbar-expand navbar-light">
                  <ul className="left-nav text-left tinfo">
                    <li className={"left-nav tactorinfonav-archive nav-link col col-auto tactorlink nav-link " + (this.isActive("hit")?"activated":"not-activated")}>
                    <div className="row flex-nowrap">
                      <div className="col">
                        <div className={"tactoricon d-flex justify-content-start"}>
                          <img src={hit} alt="" className="tactorinfoicon"/>
                        </div>
                      </div>
                      <div className="col tactinfo">
                        <h4 className="tactinfo">Hit</h4>
                        <br/>
                        <h5 className="tactinfo">Tactor 1</h5><img src={link} alt="" className="tactinfo"/><h5 className="tactinfo"> Port 1</h5>
                      </div>
                    </div>
                    </li>
                    <li className={"left-nav tactorinfonav-archive nav-link col col-auto tactorlink nav-link " + (this.isActive("pat")?"activated":"not-activated")}>
                      <div className="row flex-nowrap">
                        <div className="col">
                          <div className="tactoricon d-flex justify-content-start">
                            <img src={pat} alt="" className="tactorinfoicon"/>
                          </div>
                        </div>
                        <div className="col tactinfo">
                          <h4 className="tactinfo">Pat</h4>
                          <br/>
                          <h5 className="tactinfo">Tactor 1</h5><img src={link} alt="" className="tactinfo"/><h5 className="tactinfo"> Port 1</h5>
                        </div>
                      </div>
                    </li>
                    <li className={"left-nav tactorinfonav-archive nav-link col col-auto tactorlink nav-link " + (this.isActive("push")?"activated":"not-activated")}>
                      <div className="row flex-nowrap">
                        <div className="col">
                          <div className={"tactoricon d-flex justify-content-start "}>
                            <img src={push} alt="" className="tactorinfoicon"/>
                          </div>
                        </div>
                        <div className="col tactinfo">
                          <h4 className="tactinfo">Push</h4>
                          <br/>
                          <h5 className="tactinfo">Tactor 2</h5><img src={link} alt="" className="tactinfo"/><h5 className="tactinfo"> Port 2</h5>
                        </div>
                      </div>
                    </li>
                    <li className={"left-nav tactorinfonav-archive nav-link col col-auto tactorlink nav-link " + (this.isActive("rub")?"activated":"not-activated")}>
                      <div className="row flex-nowrap">
                        <div className="col">
                          <div className="tactoricon d-flex justify-content-start ">
                            <img src={rub} alt="" className="tactorinfoicon"/>
                          </div>
                        </div>
                        <div className="col tactinfo">
                          <h4 className="tactinfo">Rub</h4>
                          <br/>
                          <h5 className="tactinfo">Tactor 3</h5><img src={link} alt="" className="tactinfo"/><h5 className="tactinfo"> Port 3</h5>
                        </div>
                      </div>
                    </li>
                    <li className={"left-nav tactorinfonav-archive nav-link col col-auto tactorlink nav-link " + (this.isActive("shake")?"activated":"not-activated")}>
                      <div className="row flex-nowrap">
                        <div className="col">
                          <div className="tactoricon d-flex justify-content-start ">
                            <img src={shake} alt="" className="tactorinfoicon"/>
                          </div>
                        </div>
                        <div className="col tactinfo">
                          <h4 className="tactinfo">Shake</h4>
                          <br/>
                          <h5 className="tactinfo">Tactor 4</h5><img src={link} alt="" className="tactinfo"/><h5 className="tactinfo"> Port 4</h5>
                        </div>
                      </div>
                    </li>
                    <li className={"left-nav tactorinfonav-archive nav-link col col-auto tactorlink nav-link " + (this.isActive("squeeze")?"activated":"not-activated")}>
                      <div className="row flex-nowrap">
                        <div className="col">
                          <div className="tactoricon d-flex justify-content-start ">
                            <img src={squeeze} alt="" className="tactorinfoicon"/>
                          </div>
                        </div>
                        <div className="col tactinfo">
                          <h4 className="tactinfo">Squeeze</h4>
                          <br/>
                          <h5 className="tactinfo">Tactor 5</h5><img src={link} alt="" className="tactinfo"/><h5 className="tactinfo"> Port 5</h5>
                        </div>
                      </div>
                    </li>
                    <li className={"left-nav tactorinfonav-archive nav-link col col-auto tactorlink nav-link " + (this.isActive("stroke")?"activated":"not-activated")}>
                      <div className="row flex-nowrap">
                        <div className="col">
                          <div className="tactoricon d-flex justify-content-start ">
                            <img src={stroke} alt="" className="tactorinfoicon"/>
                          </div>
                        </div>
                        <div className="col tactinfo">
                          <h4 className="tactinfo">Stroke</h4>
                          <br/>
                          <h5 className="tactinfo">Tactor 6</h5><img src={link} alt="" className="tactinfo"/><h5 className="tactinfo"> Port 6</h5>
                        </div>
                      </div>
                    </li>
                    <li className={"left-nav tactorinfonav-archive nav-link col col-auto tactorlink nav-link " + (this.isActive("tap")?"activated":"not-activated")}>
                      <div className="row flex-nowrap">
                        <div className="col">
                          <div className="tactoricon d-flex justify-content-start">
                            <img src={tap} alt="" className="tactorinfoicon"/>
                          </div>
                        </div>
                        <div className="col tactinfo">
                          <h4 className="tactinfo">Tap</h4>
                          <br/>
                          <h5 className="tactinfo">Tactor 7</h5><img src={link} alt="" className="tactinfo"/><h5 className="tactinfo"> Port 7</h5>
                        </div>
                      </div>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        
          <div className="col-10 main-content">
            <section className="main-head">
              <h2>Archive</h2>
            </section>

            <section className="archive-block">
              <div className="header row">
                <div className="title col">My Touches</div>

                <div className="col">

                </div>

                <div className="col">


                </div>
              </div>

              <div className="data-block">
                <table>
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Emotion</th>
                      <th>Tactor</th>
                      <th>Date</th>
                      <th></th>
                      <th></th>
                      <th></th>
                      <th></th>
                    </tr>
                  </thead>
                    <tbody>
                      {this.state.archiveData.map((item, i) => (
                          <tr key={item.id}>
                            <td>{item.name}</td>
                            <td>{item.emotion}</td>
                            <td>{item.tactor}</td>
                            <td>{new Date(item.timestamp.split(" ").join("T")+"Z").toLocaleString('en-US', { year: 'numeric', month: 'short', day: 'numeric', hour: '2-digit', minute: '2-digit'})}</td>
                            <td><img src={playOutline} alt="" className="img-button" onClick={() => toast("Feature not active.")}/></td>
                            <td><img src={settings} alt="" className="img-button" onClick={() => this.setFromArchive(item)}/></td>
                            <td><img src={download} alt="" className="img-button" onClick={() => toast("Feature not active.")}/></td>
                            <td><img src={del} alt="" className="img-button" onClick={() => this.deleteFromArchive(item)}/></td>
                          </tr>
                        ))
                      }
                  </tbody>
                </table>
              </div>

              {/* */}
            </section>
              
          </div>
        
        </div>
      )}

</main>

</section>
<ToastContainer 
transition={Slide}
autoClose={4000}
position="top-right"
hideProgressBar={false}
newestOnTop
closeOnClick
rtl={false}
pauseOnFocusLoss
draggable={false}
pauseOnHover
/>
</div>
)

}

}

export default LeftNav;
